import { useEffect } from 'react';

import { Box } from '@nimbus-ds/components';
import { CalloutCard, Layout, Page } from '@nimbus-ds/patterns';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useTranslation } from 'react-i18next';
import { CogIcon } from '@nimbus-ds/icons';
import { useHistory } from 'react-router-dom';
import { BillingButton } from '../../components';

function PageMain() {
  const { t } = useTranslation();
  const { push } = useHistory();

  const gotoAppConfig = (path: string) => {
    if (path === '#') {
      return alert('Em breve');
    }
    push(path);
  };

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  return (
    <>
      <Page>
        <Page.Header
          title={t('MainPage.title')}
          buttonStack={<BillingButton />}
        />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="4" maxWidth={{ md: '50%' }}>
                <CalloutCard
                  appearance="neutral"
                  title={t('MainPage.ProductGridView.title')}
                  subtitle={t('MainPage.ProductGridView.subtitle')}
                  icon={CogIcon}
                  onClick={() => gotoAppConfig('/product-grid-view')}
                />
                <CalloutCard
                  appearance="neutral"
                  title={t('MainPage.GoToExcursionInfo.title')}
                  subtitle={t('MainPage.GoToExcursionInfo.subtitle')}
                  icon={CogIcon}
                  onClick={() => gotoAppConfig('#')}
                />
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
}

export default PageMain;
