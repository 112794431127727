export enum MethodOptions {
  APPEND = 'append',
  PREPEND = 'prepend',
  IN = 'in',
  AFTER = 'after',
  BEFORE = 'before',
}

export enum ThemeOptions {
  MINIMAL = 'minimal',
  TABLE = 'table',
  SHOWCASE = 'showcase',
}

export enum ThumbSizesOptions {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export interface IProductGridView {
  _id?: string;
  store_id?: number;
  is_active: boolean;
  theme: ThemeOptions;
  show_in_showcase: boolean;
  show_variant_color: boolean;
  show_variant_color_thumb: boolean;
  variant_color_size: ThumbSizesOptions;
  variant_color_name: string;
  active_scroll: boolean;
  hide_not_stock: boolean;
  message_select_products: string;
  message_cta_add_all: string;
  message_loader: string;
  reset_value_after_buy: boolean;
  initial_qty_value: number;
  script_external_id?: number | null;
  enabled_custom_elements: boolean;
  product_page_position: string;
  product_page_position_method: MethodOptions;
  product_page_mobile_position: string;
  product_page_mobile_position_method: MethodOptions;
  showcase_item_position: string;
  showcase_item_position_method: MethodOptions;
  showcase_item_mobile_position: string;
  showcase_item_mobile_position_method: MethodOptions;
  anchor_element_add_to_cart: string;
  anchor_element_qty_box: string;
  anchor_element_variants_group: string;
  anchor_element_variants_option: string;
  createdAt?: string;
  updatedAt?: string;
}

export const defaultProductGridView: IProductGridView = {
  is_active: false,
  theme: ThemeOptions.MINIMAL,
  show_in_showcase: true,
  show_variant_color: true,
  show_variant_color_thumb: true,
  variant_color_size: ThumbSizesOptions.MD,
  variant_color_name: 'Cor',
  active_scroll: true,
  hide_not_stock: true,
  message_select_products: 'Selecione pelo menos uma unidade',
  message_cta_add_all: 'Adicionar tudo',
  message_loader: 'Aguarde, estamos adicionado os produtos ao carrinho',
  script_external_id: null,
  reset_value_after_buy: true,
  initial_qty_value: 0,
  enabled_custom_elements: false,
  product_page_position: '[data-store="product-form-{product_id}"]',
  product_page_position_method: MethodOptions.BEFORE,
  product_page_mobile_position: '[data-store="product-form-{product_id}"]',
  product_page_mobile_position_method: MethodOptions.BEFORE,
  showcase_item_position: '',
  showcase_item_position_method: MethodOptions.BEFORE,
  showcase_item_mobile_position: '',
  showcase_item_mobile_position_method: MethodOptions.BEFORE,
  anchor_element_add_to_cart: '[data-store="product-buy-button"]',
  anchor_element_qty_box: '',
  anchor_element_variants_group:
    '[data-store="product-form-{product_id}"] .js-product-variants-group',
  anchor_element_variants_option:
    '[data-store="product-form-{product_id}"] .js-variation-option',
};
